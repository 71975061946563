<template>
  <div>
    <div
      class="d-flex justify-content-center align-items-center"
      v-if="
        showPreloader ||
        $store.state.refresh.fetchingNewData ||
        $store.state.refresh.waiting_filter
      "
      style="height: 90vh"
    >
      <b-spinner variant="primary" label="Text Centered" />
    </div>
    <div v-else>
      <b-row v-if="typePromocode.length > 0">
        <b-col cols="12">
          <b-card class="my-card m-0">
            <b-table
              striped
              small
              id="myTables"
              bordered
              selectable
              selected-variant="primary"
              select-mode="single"
              responsive="sm"
              head-row-variant="secondary"
              :items="typePromocode"
              :fields="fields"
              @row-clicked="clicked"
              @row-dblclicked="editForm"
              @row-contextmenu="handleContextMenuEvent"
            >
              <template #cell(checkbox)="row">
                <b-form-checkbox
                  v-model="row.detailsShowing"
                  plain
                  class="vs-checkbox-con"
                  @change="row.toggleDetails"
                >
                </b-form-checkbox>
              </template>
              <template #cell(is_active)="data">
                {{ data.item.is_active == 1 ? "Не блокирован" : "Блокирован" }}
              </template>
            </b-table>
            <vue-context ref="menu">
              <li>
                <b-link class="d-flex align-items-center" @click="addTemplate">
                  <feather-icon icon="PlusIcon" />
                  <span class="ml-75">Добавить</span>
                </b-link>
                <b-link class="d-flex align-items-center" v-b-modal.edit_type>
                  <feather-icon icon="Edit3Icon" />
                  <span class="ml-75">Изменить</span>
                </b-link>
              </li>
            </vue-context>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <modal-edit-type @refresh="refresh" :id="id"></modal-edit-type>
    <modal-add-type @refresh="refresh"></modal-add-type>
  </div>
</template>

<script>
import ModalEditType from "@/views/component/Modal/ModalTypePromocode/ModalEditType.vue";
import VueContext from "vue-context";
import ModalAddType from "@/views/component/Modal/ModalTypePromocode/ModalAddType.vue";
import { resizeable } from "@/utils/TableFunctions/resizeable";
import { cellSelect } from "@/utils/TableFunctions/cellSelect";
import typePromocodeUser from "@/utils/constants/typePromocode/type";
export default {
  components: {
    VueContext,
    ModalEditType,
    ModalAddType,
  },
  data() {
    return {
      showPreloader: false,
      typePromocode: [],
      id: null,
      fields: [
        { key: "checkbox", label: "", thStyle: { width: "30px" } },
        { key: "id", label: "ID", sortable: true, thStyle: { width: "50px" } },
        {
          key: "name",
          label: "Название",
          sortable: true,
          thStyle: { width: "50px" },
        },
        {
          key: "is_active",
          label: "Статус",
          sortable: true,
          thStyle: { width: "50px" },
        },
      ],
    };
  },
  mounted() {
    this.refresh();
    resizeable();
  },
  methods: {
    handleContextMenuEvent(item, index, event) {
      event.preventDefault();
      this.$refs.menu.open(event);
      this.id = item.id;
    },
    addTemplate() {
      this.$bvModal.show(this.$route.name + "CreateModal");
    },
    clicked(item, index, event) {
      this.id = item.id;
      this.$store.state.filterPlus.tableIndex = index;
      const clickedElement = event.target;
      const clickedCell = clickedElement.closest("td");

      if (!clickedCell) {
        return;
      }

      const columnIndex = Array.from(clickedCell.parentNode.children).indexOf(
        clickedCell
      );

      if (columnIndex < 0 || columnIndex >= this.fields.length) {
        return;
      }

      const clickedField = this.fields[columnIndex];
      this.$store.state.filterPlus.keyFilter = clickedField.key;

      this.$store.state.filterPlus.filterPlus = event.target.innerHTML.trim();
      cellSelect();
    },
    editForm() {
      this.$bvModal.show("edit_type");
    },
    refresh() {
      this.showPreloader = true;
      this.$http.get(`types-promocodes`).then((res) => {
        this.typePromocode = res.data;
        this.showPreloader = false;
        this.$store.commit("pageData/setdataCount", this.typePromocode.length);
        this.$store.commit("REFRESH_DATA", false);
      });
    },
  },
  computed: {
    fetchingNewData() {
      return this.$store.state.refresh.fetchingNewData;
    },
  },
  watch: {
    fetchingNewData() {
      this.refresh();
    },
  },
};
</script>

<style></style>
